.back-to-top {
  position: fixed;
  bottom: 25px;
  right: 25px;
  font-size: 50px;
  background: #17283f;
  color: white;
  cursor: pointer;
  border-radius: 100px;
  border: none;
  box-shadow: 0 2px 15px #ccc;
  padding: 5px;
  z-index: 1000;
}

.back-to-top:hover {
  background: green;
}


.borderLogin{
  border: 1px solid #17283f;
  border-radius: 10px;
  background: white;
}

.refreshTourch{
  border-radius: 70px;
  background: #17283f;
  cursor: pointer;
  color:white;
  padding: 8px;
  font-size: 40px;
  margin-bottom:5px;
  /* margin-left: 30px; */
}

.refreshTourch:hover{
  background: green;
}

.navLinksPadding {
  padding-top: 0.5rem;
}
.navBottomPadding {
margin-bottom:  0.3rem;
}


.isNotLoggedButton {
  color: white;
  background-color: #17283f;
  text-decoration: none;
  border-radius: 100px;
  box-shadow: 0px 2px 15px rgb(12, 11, 11);
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.isNotLoggedButton:hover {
  background-color: green;
  color: white
}

.consultaButton {
  border-radius: 70px;
  background: #42a5f5;
  cursor: pointer;
  color:white;
  padding: 8px;
  font-size: 40px;
  margin-bottom:5px;
}

.consultaButton:hover {
  background-color: green;
  color: white
}

 /* p {
margin-bottom: 0 !important;
} */